import React, { useEffect, useState } from 'react'
import "./published-file.css";
import API_BASE from '../config';
import { useNavigate } from 'react-router-dom';
export default function PublishedFile({ isAuthenticated, filesUpdated }) {

    const navigate = useNavigate();
    const [files, setFiles] = useState([]);
    useEffect(() => {

        const target = API_BASE + "/prices/archive/published";
        fetch(target, {
            credentials: "include"
        })
            .then(response => response.json())
            .then(data => {
                setFiles(data)
            })
            .catch(error => {
                console.error(error);
            });

    }, [filesUpdated, navigate, isAuthenticated])
    return (
        <div className='published'>
            <div className='published-title'>Published <div></div></div>
            <table className='published-container'>
                <tr className='published-header'>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Published at</th>
                    <th>Published by</th>
                </tr>
                {
                    files.map((f, i) => {
                        return (
                            <tr className='published-file' key={i}>
                                <td id='type'>{f.fileType}</td>
                                <td>{f.fileName}</td>
                                <td>{(new Date(parseInt(f.at))).toDateString()}</td>
                                <td>{f.by}</td>
                            </tr>
                        )
                    })

                }
            </table>
        </div>
    )
}