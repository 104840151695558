import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Pages/Login.jsx';
import Prices from './Pages/Prices.jsx';
import Home from './Pages/Home.jsx';
import "./App.css"
import "./global.css"
import "./reset.css"
import { createContext, useEffect, useState } from 'react';
import API_BASE from './config.js';

export const Context = createContext(false);
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    fetch(API_BASE + '/auth/check', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then((data) => {
        const { error, success } = data;
        setIsChecked(true);
        if (error) {
          console.log(error);
          setIsAuthenticated(false);
        } else if (success) {
          setIsAuthenticated(true);
        };
      })
      .catch((err) => {
        console.error("Error:", err.message);
        setIsAuthenticated(false);
      });
  }, [isAuthenticated, isChecked]);

  return (
    <Context.Provider value={{ isAuthenticated, isChecked, setIsAuthenticated }}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/manage" element={<Prices />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </Context.Provider >
  );
};

export default App;