import "./leads.css"
import { ReactComponent as MessageIcon } from "../assets/comment.svg";
import { ReactComponent as QuateIcon } from "../assets/format_quote.svg";
import { ReactComponent as Close } from "../assets/close.svg";
import { Fragment, useEffect, useState } from "react";
import API_BASE from "../config";
export default function Leads() {

    const [show, setShow] = useState(false)
    const [leads, setLeads] = useState([])
    const [index , setIndex] = useState()
    const [currentIndex , setCurrentIndex] = useState()
    useEffect(() => {
        fetch(API_BASE + "/leads", {
            credentials: 'include'
        })
            .then(response => response.json())
            .then(data => {
                setLeads(data)
                setShow(new Array(data.length).fill(false))
                setIndex(data.map((_,index)=>index))
            })
            .catch((err) => {
                console.log("error:", err);
            })
    }, [])
    // console.log(leads);
    const onShowMore = (i) => {
        setShow(prev => {
            let newValues = new Array(prev.length).fill(false);
            newValues[i] = true;
            return newValues;
        }
        )
        setCurrentIndex(()=>{
            const newIndex = index;
            if (newIndex[i] === index[i]) {
                return true
            }else{
                return false
            }
        })
    }
    const onClose = () => {
        setShow(false)
        setCurrentIndex(false)
    }
    return (
        <div className="leads">
            <table className= {currentIndex ? "leads-table active": "leads-table"} >
                <tr>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Email</th>
                    <th>Phone</th>
                </tr>
                {
                    leads.map((lead, index) => (
                        <tr className={show[index] === true ?"body active": "body"} key={index}>
                            <td className="FName">{(lead.firsName).toLowerCase()}</td>
                            <td className="LName">{(lead.lastName).toLowerCase()}</td>
                            <td>{lead.email}</td>
                            <td>{lead.phone}</td>
                            <button className={show[index] === true ?"more active" : "more"} onClick={() => onShowMore(index)}><MessageIcon /></button>
                        </tr>
                    ))
                }
            </table>
            <>
                {
                    leads.map((lead, index) => (
                        <div className={show[index] === true ? "side-content active" : "side-content"} key={index}>
                            <p className="header">Message
                                <button className="close" onClick={() => onClose(index)}><Close /></button>
                            </p>
                            <div>
                                <QuateIcon className="top-quote" />
                                <p>{lead.message}</p>
                                <QuateIcon className="bottom-quote" />
                            </div>
                            <p className="date"><span>{(new Date(lead.generatedAt)).toDateString()}</span> <span>{(new Date(lead.generatedAt)).getHours()}:{(new Date(lead.generatedAt)).getMinutes()} </span> </p>
                        </div>
                    ))
                }
            </>
        </div>
    )
}