import "./profile.css"
import { ReactComponent as ProfileIcon } from "../assets/profile.svg"
import { useContext, useEffect, useState } from "react"
import API_BASE from "../config";
import { useNavigate } from "react-router-dom";
import { Context } from "../App";

export default function Profile() {

    const { setIsAuthenticated } = useContext(Context);
    const [logOut, setLogOut] = useState(false);
    const [ovokeeName, setOvokeeName] = useState("")

    const navigate = useNavigate();

    const onShowLogOut = () => {
        setLogOut(p => !p);
    }
    useEffect(() => {
        const ovokeeTarget = API_BASE + "/ovokee/connected";
        fetch(ovokeeTarget, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include"
        })
            .then(async (res) => {
                if (res.status !== 200) {
                    throw new Error(await res.text());
                };
                return res.json();
            })
            .then((data) => {
                setOvokeeName((data.visitorType === "owner") ? "Administrator" : data.username);
            }).catch((error) => {
                console.log(error)
            })
    }, [])

    const onLogOut = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(API_BASE + "/auth/logout", {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    setIsAuthenticated(false);
                    navigate('/login');
                }
            } else {
                const errorData = await response.json();
                console.log(errorData)
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return (
        <div className="profile-container">
            <div className={logOut === true ? "profile active " : "profile"} onClick={onShowLogOut}>

                <ProfileIcon /> {ovokeeName}
            </div>
            <div className={logOut === true ? "menu active" : "menu"} onClick={onLogOut}>
                <div className="logout">
                    Log out
                </div>
            </div>
        </div>
    )
}
