import React, { Children, useState } from 'react'
import "./tabs.css"
import Profile from "./Profile.jsx"

export default function Tabs({ titles, children }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const handleClick = (index) => {
        setCurrentIndex(index)
    }
    return (
        <div className='tabs-container'>
            <div className="nav-container">
                <div className='tabs'>
                    {
                        titles.map((title, index) => {
                            return (
                                <div className={index === currentIndex ? "tab active" : 'tab disabled'}
                                    key={index}
                                    data-index={index}
                                    onClick={() => handleClick(index)}>
                                    {title.icon}
                                    {title.name}
                                </div>

                            )
                        })
                    }
                </div>
                <Profile />
            </div>
            <div className='children-container'>
                {Children.map(children, (child, index) => (
                    <div key={index} data-index={index} className={index === currentIndex ? 'tab-content active' : 'tab-content'}>
                        {child}
                    </div>
                ))}
            </div>
        </div>
    )
}
