import "./ovokees.css";
import { ReactComponent as RegisterIcon } from "../assets/register.svg"
import { ReactComponent as Close } from "../assets/close.svg";
import { ReactComponent as Edit } from "../assets/edit.svg";
import { ReactComponent as Password } from "../assets/password.svg";
import { useEffect, useState } from "react";
import API_BASE from "../config";

export default function Ovokees() {
    const [ovokees, setOvokees] = useState([]);
    const [register, setRegister] = useState(false);
    const [edit, setEdit] = useState([]);
    const [changePassword, setChangePassword] = useState([]);
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [refresh, setRefresh] = useState(false)
    const [message, setMessage] = useState("");
    const [lineIndex, setLineIndex] = useState();
    const [Index, setIndex] = useState();
    const [oldUsername, setOldUsername] = useState([])
    const [oldEmail, setOldEmail] = useState([])
    useEffect(() => {
        fetch(API_BASE + "/ovokee/list", {
            credentials: "include"
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setOvokees(data)
                setEdit(new Array(data.length).fill(false))
                setChangePassword(new Array(data.length).fill(false))
                setOldUsername(data.map(d => d.username))
                setOldEmail(data.map(d => d.email))
            })
            .catch(err => {
                console.log("error: ", err);
            })
    }, [refresh])
    const onRegisterNewOvokee = (e) => {
        e.preventDefault();
        fetch(API_BASE + "/auth/register", {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password, email })
        }).then(response => response.json)
            .then(data => {
                console.log(data)
                setRefresh(p => !p)
                setMessage("OVOKEE REGISTERED SUCCESSFULLY")
                setRegister(false)
                setUsername("");
                setEmail("");
                setPassword("");
                setTimeout(() => {
                    setMessage("")
                }, 6000)
            })
            .catch(err => {
                console.log("error: ", err)
                setMessage("ERROR RESETERING NEW OVOKEE")
                setRegister(false)
                setTimeout(() => {
                    setMessage("")
                }, 6000)
            })
    }

    const OnRegister = () => {
        setRegister(true)
        setEdit(false)
        setChangePassword(false)
        setIndex()
        setLineIndex()
    }
    const onEdit = (i) => {
        setRegister(false)
        setEdit(prev => {
            let newValues = new Array(prev.length).fill(false);
            newValues[i] = true;
            console.log(newValues);
            return newValues;
        })
        setLineIndex(i)
        setIndex()
        setChangePassword(false)
    }
    console.log(lineIndex);
    const OnChangePass = (i) => {
        setChangePassword(prev => {
            let newValues = new Array(prev.length).fill(false);
            newValues[i] = true;
            console.log(newValues);
            return newValues;
        })
        setLineIndex()
        setIndex(i)
        setEdit(false)
        setRegister(false)
    }
    const onClose = () => {
        setChangePassword(false)
        setRegister(false);
        setEdit(false)
        setLineIndex()
        setIndex()
    }
    const handleInputChange = (e, index, setter, oldValue) => {
        const newValue = [...oldValue];
        newValue[index] = e.target.value;
        setter(newValue);
    };

    const OnEditCreadentials = (e, ovokeeId, oldUsernameValue, oldEmailValue) => {
        e.preventDefault();
        fetch(API_BASE + "/ovokee/edit_credentials", {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "ovokeeId": ovokeeId,
                'username': oldUsernameValue,
                "email": oldEmailValue
            })
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                setRefresh(p => !p)
                setEdit(false)
                setLineIndex()
                setMessage('OVOKEE CREDENTIALS UPDATED SUCCESSFULLY')
                setTimeout(() => {
                    setMessage("")
                }, 5000)
            }).catch(err => {
                console.log(err)
                setMessage('ERROR UPDATING OVOKEE CREDENTIALS')
                setLineIndex()
                setTimeout(() => {
                    setMessage("")
                }, 5000)
            })
    }
    const [oldPassword , setOldPasword] = useState('')
    const [newPassword , setNewPasword] = useState('')
    const [confirmNewPassword , setConfirmNewPasword] = useState('')
    const [error , setError] = useState('');

    // if(confirmNewPassword !== newPassword){
    //     setError("password not compatible")
    // }

    const onChangePassword = (e, ovokeeId)=>{
        e.preventDefault();
        fetch(API_BASE + "/ovokee/change_password", {
            method : 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "ovokeeId": ovokeeId,
                'oldPassword': oldPassword,
                "newPassword": newPassword
            })
        }).then(response => response.json())
        .then(data=>{
            console.log(data);
            setMessage("PASSWORD UPDATED SUCCESSFULLY")
            setChangePassword(false)
            setIndex()
            setTimeout(()=>{
                setMessage('')
            },5000)
            setConfirmNewPasword('')
            setNewPasword('')
            setOldPasword('')
        }).catch(err=>{
            console.log(err);
            setMessage("ERROR UPDATING PASSWORD")
            setIndex()
            setTimeout(()=>{
                setMessage('')
            },5000)
        })
    }
    return (
        <>
            {
                ovokees.length ?
                    <div className="ovokees">
                        <div className="register" onClick={OnRegister}>
                            <RegisterIcon />
                            Register new ovokee
                        </div>
                        {
                            message &&
                            <div className={message.includes("ERROR") ? "error-message" : "success-message"}>{message}</div>
                        }
                        <div className="ovokees-container">
                            <table className={(register === true || edit[lineIndex] === true || changePassword[Index] === true) ? "ovokees-table active" : "ovokees-table"}>
                                <tr>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Last logged in</th>
                                    <th>Role</th>
                                </tr>
                                <tbody>
                                    {
                                        ovokees.map((ovokee, index) => (

                                            <tr key={index} className={(index === lineIndex || index === Index )? "active" : ""}>
                                                <td>{ovokee.username}</td>
                                                <td>{ovokee.email}</td>
                                                <td>{ovokee.lastLoggedIn === "NO_SESSIONS" ? "Never logged In" : ((new Date(ovokee.lastLoggedIn)).toDateString() + " — " + (new Date(ovokee.lastLoggedIn)).getHours() + ":" + (new Date(ovokee.lastLoggedIn)).getMinutes())} </td>
                                                <td><p className={ovokee.isAdmin === true ? "role admin" : "role"}>{ovokee.isAdmin === true ? "admin" : "ovokee"}</p></td>
                                                <button className={edit[index] === true ? "edit-button active" : "edit-button"} onClick={() => onEdit(index)}> <Edit /></button>
                                                <button className={changePassword[index] === true ? "password-button active" : "password-button"} onClick={()=>OnChangePass(index)}><Password /></button>
                                            </tr>

                                        ))
                                    }
                                </tbody>
                            </table>
                            <div className={register === true ? "side-register-div active" : "side-register-div"}>
                                <p className="header">Register new ovokee<button className="close" onClick={onClose}><Close /></button> </p>
                                <form action="" className="register-form">
                                    <div className="input-container">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                placeholder="Username"
                                                className="login-input reset-button"
                                                required
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group">
                                            <input
                                                type="email"
                                                placeholder="Email"
                                                className="login-input reset-button"
                                                required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group">
                                            <input
                                                type="Password"
                                                placeholder="password"
                                                className="login-input reset-button"
                                                required
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <button onClick={onRegisterNewOvokee} >Save</button>
                                </form>
                            </div>
                            {
                                ovokees.map((ovokee, index) => {
                                    const oldUsernameValue = oldUsername[index];
                                    const oldEmailValue = oldEmail[index];
                                    return (
                                        <div className={edit[index] === true ? "side-edit-div active" : "side-edit-div"} key={index}>
                                            <p className="header">Change your informations<button className="close" onClick={onClose}><Close /></button> </p>
                                            <form action="" className="register-form">
                                                <div className="input-container">
                                                    <div
                                                        className="input-group">
                                                        <input
                                                            type="text"
                                                            placeholder="Username"
                                                            className="login-input reset-button"
                                                            value={oldUsernameValue}
                                                            onChange={(e) => handleInputChange(e, index, setOldUsername, oldUsername)}
                                                        />
                                                    </div>
                                                    <div className="input-group">
                                                        <input
                                                            type="email"
                                                            placeholder="Email"
                                                            className="login-input reset-button"
                                                            value={oldEmailValue}
                                                            onChange={(e) => handleInputChange(e, index, setOldEmail, oldEmail)}
                                                        />
                                                    </div>
                                                </div>
                                                <button onClick={(e) => OnEditCreadentials(e, ovokee.ovokeeId, oldUsernameValue, oldEmailValue)}>Save changes</button>
                                            </form>
                                        </div>
                                    )
                                })
                            }
                            {
                                ovokees.map((ovokee, i)=>(
                                    <div className={changePassword[i] === true ? "side-update-div active" : "side-update-div"}>
                                        <p className="header">Change password<button className="close" onClick={onClose}><Close /></button> </p>
                                        <form action="" className="register-form">
                                            <div className="input-container">
                                                <div
                                                    className="input-group">
                                                    <input
                                                        type="password"
                                                        placeholder="old password"
                                                        className="login-input reset-button"
                                                        value={oldPassword}
                                                        onChange={(e)=>setOldPasword(e.target.value)}
                                                    />
                                                </div>
                                                <div className="input-group">
                                                    <input
                                                        type="password"
                                                        placeholder="new password"
                                                        className="login-input reset-button"
                                                        value={newPassword}
                                                        onChange={(e)=>setNewPasword(e.target.value)}
                                                    />
                                                </div>
                                                <div className="input-group">
                                                    <input
                                                        type="password"
                                                        placeholder="confirm new password"
                                                        className="login-input reset-button"
                                                        value={confirmNewPassword}
                                                        onChange={(e)=>setConfirmNewPasword(()=>{
                                                            if(e.target.value !== newPassword){
                                                                setError('password not compatible')
                                                            }
                                                            else{
                                                                setError("")
                                                                return e.target.value
                                                            }
                                                        })}
                                                    />
                                                </div>
                                                <div style={{color: "red", fontSize : "14px"}}>{error}</div>
                                            </div>
                                            <button onClick={(e)=>onChangePassword(e, ovokee.ovokeeId)}>Save changes</button>
                                        </form>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    :
                    <div className="not-auth">
                        YOU'RE UNAUTHORIZED TO SHOW THE OVOKEES LIST
                    </div>
            }
        </>
    )
}