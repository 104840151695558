import "./nav-bar.css";
import Tabs from "./Tabs";
import { ReactComponent as PricesIcon } from "../assets/prices.svg"
import { ReactComponent as LeadsIcon } from "../assets/leads.svg"
import { ReactComponent as OvokeesIcon } from "../assets/ovokees.svg"
import PublishedFile from '../Components/PublishedFile'
import UploadButton from '../Components/UploadButton'
import UnpublishedFile from '../Components/UnpublishedFile'
import { useState } from "react";
import Leads from "../Pages/Leads";
import Ovokees from "../Pages/Ovokees";

export default function NavBar() {

  const [filesUpdated, setFilesUpdated] = useState(false);
  const [refresh, setRefresh] = useState(false);
  return (
    <div className="nav-bar">
      <Tabs
        titles={[
          {
            icon: <PricesIcon />,
            name: "Prices"
          },
          {
            icon: <LeadsIcon />,
            name: "Leads"
          },
          {
            icon: <OvokeesIcon />,
            name: "Ovokees"
          }
        ]}
      >
        <div className='displayed-content'>
          <div className='published'>
            <UploadButton setFilesUpdated={setFilesUpdated} />
            <PublishedFile filesUpdated={refresh} />
          </div>
          <div className='unpublished'>
            <UnpublishedFile filesUpdated={filesUpdated} setRefresh={setRefresh} refresh={refresh} />
          </div>
        </div>
         <Leads />
         <Ovokees />
      </Tabs>

    </div>
  )
}
