import React, { useEffect, useState } from 'react'
import "./unpublished-file.css";
import { ReactComponent as InsertIcon } from "../assets/insert.svg"
import API_BASE from '../config';
import PopUp from './PopUp';
import { ReactComponent as SuccesIcon } from "../assets/success.svg"
import { ReactComponent as ErrorIcon } from "../assets/error.svg"

export default function UnpublishedFile({ filesUpdated, setRefresh, refresh }) {
    const [files, setFiles] = useState([]);
    const [message, setMessage] = useState('');
    const [showPopUp, setShowPopUp] = useState(false);
    const [fileId, setFileId] = useState()
    const [selected, setSelected] = useState();
    // console.log(fileId)
    useEffect(() => {
        const target = API_BASE + "/prices/archive/unpublished";
        fetch(target, {
            credentials: "include"
        })
            .then(response => response.json())
            .then(data => {
                // console.log(data);
                setFiles(data)
                setSelected(data.map(f => false))
            })
            .catch(error => {
                console.error(error);
            });

    }, [refresh, filesUpdated])

    const onPublish = () => {
        const publishURL = API_BASE + "/prices/archive/publish"
        fetch(publishURL, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                fileId: fileId
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log(data);
                    setRefresh(p => !p)
                    setShowPopUp(true)
                    setMessage("File published successfully")
                    setFileId()
                }
                else if (data.error) {
                    setMessage("Error publishing file")
                    setShowPopUp(true)
                    setFileId()
                }
            })
            .catch(error => {
                console.error(error);
                setMessage("Error publishing file")
                setShowPopUp(true)
                setFileId()
            });
    }
    const closePopUp = () => {
        setShowPopUp(false)
    }
    const handleClick = (id, i) => {
        setFileId(id);
        setSelected(prev => {
            let newSelected = new Array(prev.length).fill(false);
            newSelected[i] = !newSelected[i];
            console.log(newSelected);
            return newSelected;
        })
    }
    // console.log(selected)
    return (
        <div className='unpublished'>
            <button className={fileId ? 'action active' : 'action'} disabled={!fileId} onClick={onPublish}>Publish <InsertIcon /></button>
            <div className='unpublished-title'> Unpublished <div></div></div>
            <table className='unpublished-container'>
                <tr className='unpublished-header'>
                    <th>Type</th>
                    <th>Name</th>
                    <th>uploaded at</th>
                    <th>uploaded by</th>
                </tr>
                <tbody>


                    {
                        files.map((f, i) => {
                            return (

                                <tr onClick={() => handleClick(f.fileId, i)} className={selected[i] === true ? 'unpublished-file active' : 'unpublished-file'} key={i}>
                                    <td id='type'>{f.fileType}</td>
                                    <td>{f.fileName}</td>
                                    <td>{(new Date(parseInt(f.at))).toDateString()}</td>
                                    <td>{f.by}</td>
                                </tr>
                            )
                        })

                    }
                </tbody>
            </table>
            {
                message &&

                <PopUp show={showPopUp}>
                    {
                        message.includes("successfully") ?
                            <div className='success'>
                                <SuccesIcon />
                                {message}
                                <button onClick={closePopUp}>Ok</button>
                            </div> :
                            <div className='error'>
                                <ErrorIcon />
                                {message}
                                <button onClick={closePopUp}>Try again</button>
                            </div>
                    }
                </PopUp>
            }
        </div>
    )
}