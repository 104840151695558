import "./upload-button.css"
import { ReactComponent as UploadIcon } from "../assets/upload.svg"
import API_BASE from "../config";
import { useState } from "react";

export default function UploadButton({ setFilesUpdated }) {
  const [message, setMessage] = useState("");
  const onUpload = (e) => {
    const endpoint = API_BASE + '/prices/archive/upload';
    const file = e.target.files[0];
    console.log(file)
    const formData = new FormData();
    formData.append('file', file);
    formData.append('tableName', "file");
    formData.append('encoding', "utf-8");
    fetch(endpoint, {
      method: 'POST',
      credentials: "include",
      body: formData
    })
      .then(response => JSON.parse(JSON.stringify(response)))
      .then(data => {
        console.log(data);
        setFilesUpdated(p => !p)
        setMessage('file uploaded successfully')
        setTimeout(() => {
          setMessage('')
        }, 5000)

      })
      .catch(error => {
        console.error(error);
        setMessage('Error uploading file')
        setTimeout(() => {
          setMessage('')
        }, 5000)

      });
  }
  return (
    <div className="upload-div">
      <div className='upload-button'>
        <input type="file" onChange={onUpload} />
        <button>
          <UploadIcon /> Upload file
        </button>
      </div>
      {
        message &&
        <p className={message.includes("successfully") ? "success" : "error"}>{message}</p>
      }
    </div>
  )
}
