import React, { useContext, useEffect } from 'react'
import "./prices.css"
import NavBar from '../Components/NavBar'
import { Context } from '../App';
import { useNavigate } from 'react-router-dom';


const Prices = () => {
  const { isChecked, isAuthenticated } = useContext(Context);
  const navigate = useNavigate();
  useEffect(() => {
    if (isChecked && !isAuthenticated) {
      navigate("/login");
    };
  });

  if (isChecked && isAuthenticated) {
    return (
      <div className='prices'>
        <NavBar />
      </div>
    )
  } else {
    return (<></>)
  }
};
export default Prices;