import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as OvokyLogo } from "../assets/ovoky-logo.svg"
import { ReactComponent as Username } from "../assets/username.svg"
import { ReactComponent as Password } from "../assets/lock.svg"
import { ReactComponent as Arrow } from "../assets/arrow.svg"
import './login.css';
import API_BASE from '../config';
import { Context } from '../App';

const Login = () => {
    // Auth conditional rendering
    const { isAuthenticated, isChecked, setIsAuthenticated } = useContext(Context);
    const navigate = useNavigate();
    useEffect(() => {
        if (isChecked && isAuthenticated) {
            navigate('/manage');
        };
    }, [isChecked, isAuthenticated, navigate])


    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');
        try {

            // if (!isAuthenticated) {
            const response = await fetch(API_BASE + '/auth/login', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    setIsAuthenticated(true);
                    navigate('/manage');
                }
                else {
                    setIsAuthenticated(false);
                    setError(data.error)
                }
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Login failed');
            }
            // }
        } catch (error) {
            console.error('Error during login:', error);
            setError('An error occurred. Please try again.');
        }
    };

    console.log(isAuthenticated);
    if (isChecked && !isAuthenticated) {
        return (
            <div className='container'>
                <OvokyLogo />
                <div className="login">
                    <h1
                        className="login-title">Welcome Back,
                        <span> Ovokee</span>!
                    </h1>

                    <form
                        onSubmit={handleLogin}
                        className='login-form'>
                        <div
                            className="input-group">
                            <Username />
                            <input
                                type="text"
                                placeholder="username"
                                className="login-input reset-button"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <Password />
                            <input
                                type="password"
                                placeholder="password"
                                className="login-input reset-button"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="login-button reset-button">Login <Arrow /></button>
                        {
                            error &&
                            <p className="error-message">{error}</p>
                        }
                    </form>
                </div>
            </div>

        );
    } else {
        return (<></>)
    }
};

export default Login;