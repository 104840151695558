import React from 'react';
import './popup.css';

const PopUp = ({ show, children }) => {

  return (
    <div className={show ? "popup display-block" : "popup display-none"}>
      <section className="popup-main">
        {children}
      </section>
    </div>
  );
};

export default PopUp;
